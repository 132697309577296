.gameDapp-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/images/background/sommi-fan-token-logo-white-tiled-077.png");
    background-repeat: repeat repeat;
    background-size: 70%;
  }

  #game-lobby{
    position: relative;
    top: 12em;
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the minimum and maximum width as needed */
    grid-gap: 101px; /* Adjust the gap between grid items */
  }

  #game-lobby div{
    max-height: 20em;
  }

  #game-lobby div div{
    //background-color: rgba(255, 255, 255, 0.7);
  }

  #game-lobby div :hover{
    //background-color: rgba(255, 255, 255, 0.9);

  }

  #game-lobby img{
    margin: 0 auto;
    max-height: 120px;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.0) !important;
  }
  #game-lobby h2{
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.0) !important;
  }
  #game-lobby p{
    text-align: center;
    background-color: rgba(255, 255, 255, 0.0) !important;
  }