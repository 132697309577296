@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css?family=Orbitron");
@import url("https://fonts.googleapis.com/css?family=Sport+Numbers");
:root {
  --purple-1: #8E84A9;
  --purple-2: #CCC8D9;
  --button-color-0: #750C8C;
  --button-color-1: #890ea3;
  --button-color-1: #610a75;
  --cake-1: #1ebcc8;/*#1fc7d4;*/
  --cake-2:#1fc7d4; /*#21d1de;/**/
  --eggp-1:#750C8C;
  --eggp-2:#750C8C;
  --busd-1:#D6BD33;
  --busd-2:#000000;
  --ui-1: #A4A49B;
  --background-1: linear-gradient(315deg, #000000 0%, #181810 74%); /*#181810;*/
  --background-2: #8E84A9;/*0E0E0E*/
  --wrapper-2: radial-gradient(50% 70%, rgb(123, 78, 183) 0%, rgb(34, 19, 45) 100%);
  --wrapper-3: rgba(0, 0, 0, 0) linear-gradient(rgb(255, 255, 255) 33%, rgb(184, 165, 193) 100%) repeat scroll 0% 0%;
  --background-3: linear-gradient(315deg, #7a6eaa 0%, #645993 74%);
  --background-4: linear-gradient(180deg, rgba(221,45,14,1) 1%, rgba(187,21,21,1) 100%);
  --background-5:black;
  --gradient-1: linear-gradient(90deg, rgba(142, 132, 174, 1) 0%, rgba(204, 200, 217, 1) 33%, rgba(185, 179, 204, 1) 68%, rgba(142, 132, 174, 1) 100%);
  --gradient-2: linear-gradient(90deg, #D6C7BC 0%, #F0EFEF 33%, #F0EFEF 68%, #D6C7BC 100%);
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.wrapper {
  font-family: 'Sport Numbers','Signika Negative', sans-serif;
  display: -ms-grid;
  display: grid;
  place-content: center;
  padding: 40px;
  min-height: 70vh;

}
.infolink button{
  height: 5px;
  background: red;
  font-size:10px;
}

h3{
  font-size:24px;
  text-shadow: 0px 0px 1px white;
}
h3.EGGP{
  color: #BA85C5;
}
h3.CAKE{
  color: var(--cake-1);
}
h3.winner{
    color:#d4af37;
    text-shadow: 0px 0px 1px white;
}
.gameDiv {
  background: var(--background-5);
  padding-right: 120px;
  padding-left: 120px;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-box-shadow: 0px 0px 21px 8px #BA85C5; /*#FFFFFF;*/
  box-shadow: 0px 0px 21px 8px #BA85C5; /*#FFFFFF;*/
  border-radius: 5%;
  -webkit-transform: scale(0.6, 0.6);
          transform: scale(0.6, 0.6);

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  /* Large devices (desktops, 992px and up) */
  /* Extra large devices (large desktops, 1200px and up) */
}
.gameDiv.CAKE{
  background: var(--background-5); /*var(--background-1);*/
  -webkit-box-shadow: 0px 0px 21px 8px #1ebcc8;
}
.slotsContainer{
  position: relative;
  margin-top: 35em;
}
@media (min-width: 320px) {
  .gameDiv, .slotSpan {
    -webkit-transform: scale(0.42, 0.42);
            transform: scale(0.42, 0.42);

  }
  .gameDiv{
    margin-top:-285px;
  }
  .wrapper{
    height:500px;
    padding-top: 250px;
    padding-bottom: 0px;
  }
}
@media (min-width: 360px) {
  .gameDiv, .slotSpan {
    -webkit-transform: scale(0.48, 0.48);
            transform: scale(0.48, 0.48);
  }
  .gameDiv{
    margin-top:-255px;
  }
  .wrapper{
    height:500px;
    padding-top: 240px;
  }
}
@media (min-width: 576px) {
  .gameDiv, .slotSpan {

    -webkit-transform: scale(0.70, 0.70);
            transform: scale(0.70, 0.70);
  }
  .gameDiv{
    margin-top:-140px;
  }
  .wrapper{
    padding-bottom: 140px;
  }
}

@media (min-width: 768px) {
  .gameDiv, .slotSpan {
    -webkit-transform: scale(0.75, 0.75);
            transform: scale(0.75, 0.75);
  }
  .gameDiv{
    margin-top:-125px;
  }
  .wrapper{
    padding-bottom: 300px;
  }
}


@media (min-width: 992px) {
  .gameDiv, .slotSpan {
    -webkit-transform: scale(0.75, 0.75);
            transform: scale(0.75, 0.75);
  }
  .gameDiv{
    margin-top:-125px;
  }
  .wrapper{
    padding-bottom: 350px;
  }
}

@media (min-width: 1200px) {
  .gameDiv, .slotSpan {
    -webkit-transform: scale(0.85, 0.85);
            transform: scale(0.85, 0.85);
  }

  .wrapper{
    padding-bottom: 350px;
  }
}

.gameDiv .top-ui {
  color: #6C6C61;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  max-width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 10px;

}

.gameDiv .top-ui .wallet-data{
  width: 200px;
  -ms-flex-line-pack: left;
      align-content: left;
  text-align: left;
}

.gameDiv .top-ui .wallet-data :nth-child(1) {
  color: white; /*var(--ui-1);*/
  font-weight: bold;
  font-size: 1.5em;
}

.gameDiv .top-ui .wallet-data :nth-child(3) {
  color: white;
  font-weight: bold;
  font-size: 1.5em;
}

.gameDiv .top-ui .buttons {
  text-align: right;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (2fr)[1];
      grid-template-columns: repeat(1, 2fr);
  gap: 5px;
}

.gameDiv .top-ui .buttons button {
  display: inline-block;
  padding: 15px;
  height: 50px;
  background: #750C8C;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 22px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 0px #750c8c;
          box-shadow: 0 5px 0px #750c8c;
  border-bottom: 2px solid #690b7e;
}

.gameDiv .top-ui .buttons button:hover {
  background: #610a75;
  -webkit-box-shadow: 0 4px 1px #610a75;
          box-shadow: 0 4px 1px #610a75;
  border-bottom: 2px solid #560966;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.gameDiv .top-ui .buttons button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  border-bottom-width: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.gameDiv .bottom-ui {
  padding: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[9];
      grid-template-columns: repeat(9, 1fr);
  gap: 1em;
  width: 100px;
  padding-left:20px;
}

.gameDiv .bottom-ui button {
  display: inline-block;
  padding: 15px;
  height: 50px;
  background: var(--button-color-0); /* #750C8C; */
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 22px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 0px var(--button-color-0); /* #750c8c; */
          box-shadow: 0 5px 0px var(--button-color-0); /* #750c8c; */
  border-bottom: 2px solid #690b7e;
}

.gameDiv .bottom-ui button:hover {
  background: var(--button-color-1);
  -webkit-box-shadow: 0 4px 1px var(--button-color-1);
          box-shadow: 0 4px 1px var(--button-color-1);
  border-bottom: 2px solid #560966;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.gameDiv .bottom-ui button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  border-bottom-width: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.gameDiv .bottom-ui .inputRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.gameDiv .bottom-ui .inputRow input {
  display: inline-block;
  padding: 15px;
  height: 55px;
  width: 100px;
  outline: none;
  font-size: 20px;
}

.gameDiv .bottom-ui .inputRow input::-webkit-outer-spin-button,
.gameDiv .bottom-ui .inputRow input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fields {
  margin-top: 10px;
  padding-bottom: -10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  gap: 0.4em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  color: white;
  font-size: 20px;
  padding-top:20px;
  padding-left:20px;
  border-radius: 5%;
}
.fields.EGGP{
  /*background: var(--eggp-2);*/
  background-color: #e056fd;
background-image: linear-gradient(0deg, #e056fd 0%, #000000 15%);
  box-shadow: inset 0 0  5px #BA85C5; /*#FFFFFF;*/
  /*-webkit-box-shadow: 0 5px 0px var(--button-color-0); /* #750c8c; */
          /*box-shadow: 0 5px 0px var(--button-color-0);*/
}
.fields.CAKE{
  background-color: #e056fd;
background-image: linear-gradient(0deg, var(--cake-1) 0%, #000000 15%); /* background:  var(--cake-1);/ */
  box-shadow: inset 0 0 5px var(--cake-1);
/*-webkit-box-shadow: 0 5px 0px var(--cake-1); /* #750c8c; */
          /*box-shadow: 0 5px 0px var(--cake-1);*/
}

.spinner-div-1, .spinner-div-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.spinner-div-1 .icons{
  height: 564px;
  /*background: url(./assets/pngs/266da510-0a38-4a0d-b8a6-ac208c37eaf9.png) repeat-y, var(--gradient-1);*/
  background: url(./assets/pngs/Lottery-line.png) repeat-y, var(--gradient-2);
  will-change: backgroundPosition;
  -webkit-transition: 0.3s background-position ease-in-out;
  transition: 0.3s background-position ease-in-out;
  border-radius: 10px;
  background-position: cover;
  width: 150px;
  cursor: none;
  border-style: solid;
}
.spinner-div-1.CAKE .icons{
  background: url(./assets/pngs/Lottery-line.png) repeat-y, var(--gradient-2);
}
.spinner-div-1.EGGP .icons{
  background: url(./assets/pngs/266da510-0a38-4a0d-b8a6-ac208c37eaf9.png) repeat-y, var(--gradient-1);
}
/*# sourceMappingURL=Slotmachine.css.map */
.slotSpan{
  width:95%;
  margin-left: 15px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding-top: 7px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 5px;
  border-radius: 5px;
   /*var(--gradient-2);*/
}
.slotSpan.EGGP{
  /* background: var(--gradient-1);*/
}
.slotSpan.CAKE{
  /* background: var(--gradient-2); */
}
.slotSpan .currencyButton button {
  display: inline-block;
  padding: 15px;
  height: 50px;
  width:47.5%;
  margin-left: 2%;
  background: #750C8C;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 22px;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 0px #750c8c;
          box-shadow: 0 5px 0px #750c8c;
  border-bottom: 2px solid #690b7e;
}
.slotSpan .currencyButton .cake button,
.gameDiv .top-ui.CAKE .buttons button,
.gameDiv .bottom-ui.CAKE button {
display:inline;
  background: var(--cake-1);
  -webkit-box-shadow: 0 5px 0px #1fc7d4;
          box-shadow: 0 5px 0px #1fc7d4;
  border-bottom: 2px solid #1ba7b1;
}
.slotSpan .currencyButton .eggp button{
float:left; display:inline;
  background: var(--eggp-2);
  -webkit-box-shadow: 0 5px 0px var(--eggp-2);
          box-shadow: 0 5px 0px var(--eggp-2);
  border-bottom: 2px solid #690b7e;
}
.slotSpan .currencyButton .eggp button:hover {
  background: var(--eggp-1);
  -webkit-box-shadow: 0 4px 1px var(--eggp-2);
          box-shadow: 0 4px 1px var(--eggp-2);
  border-bottom: 2px solid #560966;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.slotSpan .currencyButton .eggp button:active {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  border-bottom-width: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.slotSpan .currencyButton .cake button:hover,
.gameDiv .top-ui .buttons.CAKE button:hover,
.gameDiv .bottom-ui.cake button:hover
 {
  background: var(--cake-2);
  -webkit-box-shadow: 0 4px 1px var(--cake-2);
          box-shadow: 0 4px 1px var(--cake-2);
  border-bottom: 2px solid #1ba7b1;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.slotSpan .currencyButton .cake button:active
 {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  border-bottom-width: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
