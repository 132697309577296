.card_container{
    position: relative;
    top: 3em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .card_border{
    padding: 20px;
    width: 60%;
    height: 90%;
    border: 1px solid black;
    border-radius: 8px;
    text-align: center;
    }
  
  .card_img{
    width: 15em;
    border: 2px solid black;
    justify-content: center;
    align-items: center;
  }

  .depressionStyle{
    background-color: lightGrey;
    margin-left: 10px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }