/* Base Style */
.info-panel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.pool-info, .next-draw-panel, .participate-panel {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  margin: 0px;
  flex: 1; /* Make all panels take equal space */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to all panels */
}
.participate-panel{
  background-color: black;
}
.next-draw-panel {
  max-width: 320px;
  font-size: 10pt;
  background-color: #ffeb3b !important; /* Bright yellow background to draw attention */
  //background-color: #fff !important;
  border: 2px solid #fbc02d !important; /* Slightly darker yellow border */
}
.next-draw-panel > h3{
  color: black;
}

.pool-info h3, .next-draw-panel h2, .participate-panel h3 {
  margin: 0;
}

.participate-panel, .pool-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px !important;
  max-width: 320px;
  font-size: 14pt;
  //background: rgb(18,16,30);
  //background: linear-gradient(354deg, rgba(18,16,30,1) 42%, rgba(0,29,58,1) 94%);
}

.pool-info {
  /*display: flex;
  gap: 10px;
  flex-direction: column;*/
  padding: 20px !important;
  justify-content: space-between;
}



.ticket-logo {
  position: 'absolute';
  width: 350px;
  height: 120px;
}
.holders-logo {
  position: relative;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid black;
  left: 15px;
  width: calc(100% + 30px); /* Increase width to account for 15px padding on both sides */
  margin: 0 -15px; /* Negative margin to counteract the 15px padding of the container */
}
.holder-id-row{
  display: flex;
  justify-content: center;
  gap: 0px;
}
.tickets {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  overflow-y: auto; /* Enables vertical scrolling */
  margin-top: 0px;
  margin-bottom: 10px;
  opacity: 0.98;
  justify-content: center;

}
.ticket-inner {
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: baseline; /* Correct alignment */

}
.ticket-inner span{
  max-width: 100%;
}
.winner-id-row{
  display: flex;
  gap: 20px;
}

.ticket-inner-holders span i {
  display: inline-block;  /* Ensure it's treated like a block element */
  max-width: 100%;        /* Constrain the width to enable ellipsis */
  white-space: nowrap;     /* Prevents text from wrapping */
  overflow: hidden;        /* Ensures overflow is hidden */
  text-overflow: ellipsis; /* Displays ellipsis for overflowed text */
}

.ticket-inner-winners{
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  height: 100%;
  /* background-color: #d0d461; /* Light blue background */
  color: white;
  background-color: #313131; /*black; */
  border-radius: 8px;
  padding: 20px 35px;
  text-align: start;
  border: 2px solid #87ceeb; /* Soft border to match the card */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.ticket-inner-winner > span{
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.winner-text {
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  color: #ffe4b5; /* #ededed; #1e90ff; Bright blue text */
  letter-spacing: 1px;
}
.winner-text-amount {
  display: flex;
  font-size: 14pt; /* Make the number much larger */
  font-weight: bold;
  padding: 0px 20px;
  justify-content: center;
  color: yellow; /*  #ff6347; Bright orange-red to stand out */
  text-shadow: 0px 0px 10px rgb(255, 215, 0); /* Soft glow effect */
  margin-top: 10px;
  animation: pop 1s ease-in-out infinite alternate; /* Simple animation */
}

.edging{
  color: #000;
  padding: 10px;
  border: 2px solid #141212;
  background-color: white;
  outline: none;
  transition: 0.4s;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.ticket {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  max-height: 500px;
  background-color: rgb(43, 58, 75, 0.7);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  padding: 0px;
  font-family: Arial, sans-serif;
  color: #000; /* #ffffff; */
  font-size: 15px;
}

.ticket h4 {
  margin-top: 0;
  color: #000; /* #ffffff; */
}

.ticket p {
  margin: 5px 0;
}
.rollover-panel {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  height: 100%;
  /* background-color: #d0d461; /* Light blue background */
  color: white;
  background-color: black;
  border-radius: 8px;
  padding: 20px 35px;
  text-align: start;
  border: 2px solid #87ceeb; /* Soft border to match the card */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.rollover-panel > span{
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.rollover-text {
  font-size: 28px;
  text-align: center;
  font-weight: bold;
  color: #1e90ff; /* Bright blue text */
  letter-spacing: 1px;
}
.glow-text {
  display: flex;
  font-size: 24; /* Make the number much larger */
  font-weight: bold;
  padding: 0px 20px;
  justify-content: center;
  color: #ff6347; /* Bright orange-red to stand out */
  text-shadow: 0px 0px 10px rgba(255, 99, 71, 0.8); /* Soft glow effect */
  animation: pop 1s ease-in-out infinite alternate; /* Simple animation */
}
@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1); /* Slight scale to give a pop effect */
  }
}
.pagination-container {
  margin-top: 20px;
}

.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  background-color: #333; /* Dark background for contrast */
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: rgba(232, 12, 12, 0.09) 0px 2px 1px,
              rgba(0, 0, 0, 0.19) 0px 4px 2px,
              rgba(0, 0, 0, 0.49) 0px 8px 4px,
              rgba(0, 0, 0, 0.09) 0px 16px 8px,
              rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.current-page-box {
  background-color: #f7f7f7;
  border: 1px solid #c3cfe2;
  border-radius: 8px;
  width: 60px;
  height: 36px;
  margin: 0 10px;
  text-align: center;
  font-size: 18px;
  line-height: 36px;
}

.custom-pagination-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333; /* Dark background for contrast */
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: rgba(232, 12, 12, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.pagination-button{
  width: 100px;
  margin: 0px;
}
.pagination_input {
  width: 20px;
  max-width: 20px;
  height: 36px;
  border: 1px solid #c3cfe2;
  border-radius: 8px;
  margin: 0 5px;
  text-align: center;
}

.pagination_input:focus {
  outline: none; /* Remove default outline */
  border-color: #ffa500; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(255, 165, 0, 0.5); /* Glow effect */
}
.pagination_ellipsis{
  font-size: 28px;
  position: relative;
  width: 50px;
}
.pagination-item{
  box-shadow: none;
}
.arrow-head{
  font-size: 32px;
  color: red;
}
.selected_page{
  border: 3px solid gold;
}
.draw-now-panel {
  background-color: #111111; /* red #111111; /* A bright red to stand out on yellow */
  color: gold ; /*#fff;   */
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16pt;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #fff; /* White border for more emphasis */
  opacity: 0.95;
}
.trophy-icon {
  /* min-width: 120px; */
  height: 120px;
  padding-left: 40px;
}
.lotto-icon {
  /* min-width: 120px; */
  height: 100px;
  padding-left: 100px;
  padding-top:10px;
}

.info-panel-inner {
  background-color: black; /* #22b04c;  A bright red to stand out on yellow */
  color: #fff; /* White text for contrast */
  padding: 20px;
  height: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 9pt;
  border-radius: 15px; /* 35px;  */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 3px solid #fff; /* White border for more emphasis */
  opacity: 0.9;
  paint-order: stroke fill;
}

.info-panel-inner h3 {
  color: light-grey;
  font-size: 15pt;
  /*color:#1e90ff;*/
}

/* Medium Screen */
@media (max-width: 1024px) {
  .info-panel {
    grid-template-columns: 1fr 1fr; /* Equal column width */
    grid-template-rows: auto auto;
    gap: 15px;
  }
  .pool-info {
    grid-column: 1 / 2; /* Occupies the first column */
    grid-row: 2 / 3;    /* Second row */
  }
  .next-draw-panel {
    grid-column: 1 / 3; /* Spans across both columns */
    grid-row: 1 / 2;    /* First row */
  }
  .ticket-buy {
    grid-column: 2 / 3; /* Occupies the second column */
    grid-row: 2 / 3;    /* Second row */
  }
}

/* Small Screen */
@media (max-width: 770px) {
  .info-panel {
    grid-template-columns: 1fr;
    gap: 10px;
    max-width: 500px !important;
    margin-left: auto; /* Center the element */
    margin-right: auto; /* Center the element */
    max-width: 100%; /* Ensure it takes full width in mobile */
  }
  .next-draw-panel {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .pool-info {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  .tickets{
    max-width: 700px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;

  }
.ticket{
  margin-left: auto;
  margin-right: auto;
  min-width: 500px !important;
}

.custom-pagination-buttons{
  max-width: 600px;
}
}
