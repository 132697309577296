.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: 0 0px;
}
.gallery-container{
    width: 100%
}
.react-images__blanket{
    height: 100%;
    width: 100%
}
.react-images__positioner{
    height: 100%;
    width: 100%
}
.react-images__track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
.css-1ycyyax{
    z-index: 5;
}
.react-images__view-wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
}

.fullscreen{
    display: 'flex' !important;
    justify-content: center  !important;
    align-items: center  !important;
}
#meme-container{
    display: flex;
    justify-content: center;
    margin: 5%;
}
.memePs{
   padding:15px;
}
.meme-head{
   padding-top:25px;
   color: black;
}
.bg-rpt{
  background-image: url("../../assets/images/background/sommi-fan-token-logo-white-tiled-077.png");
  background-repeat: repeat repeat;
  background-size: 70%;
  //background-color:#fef200;
  //opacity:0.20;
  z-index: -5;
}



.meme-gallery-img {
    margin: 3px;
    display: block;
    cursor: pointer;
    border: 3px solid black;
    border-radius: 5px;
    transition-duration: 0.5s;
}

.meme-gallery-img:hover{
      //opacity: 0.9;
      border: 3px solid yellow;
      box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
      transform: translateY(-5px);

}

#meme-button{
  color:red;
  border: 3px solid black;
  background-color: white;
  transition-duration: 0.5s;
}
#meme-button:hover{
  background-color:yellow;
  border: 3px solid white;
}

@media (max-width: 1000px){
    #meme-container{
        display: flex;
        margin: 0%;
}}
