.button{
  display:inline-flex;
  font-size:14px;
  background-color: rgb(233,233,237);
  letter-spacing:0px;
  font-weight:700;
  line-height:16px;
  text-decoration:none !important;
  text-transform:uppercase;
  color: black !important; //#fff !important;
  border: 2px solid black;
  border-radius:10px;
  cursor:pointer;
  justify-content:center;
  padding:16px 32px;
  margin-left: 10px; /* Adjust this value as needed */
  margin-right: 10px;
  height:48px;
  text-align:center;
  white-space:nowrap;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.button:hover {
  background-color: blueviolet; /* #1e90ff; */
  //color:#ededed !important;
  ///color:#000 !important;
}

.form_button{
    background-color: #fef200;/*#273C5A; */
  }
  .dapp_button{
    background: #7abd22;
  }
  .dapp_button:hover{
    background: rgba(75, 250, 75, 0.274);
  }
  .form_button:hover{
      background:#293e5e;
  }
  .buy_button{
    background: #7abd22;
  }
  .button:active{
      outline:0
  }

  .home_btn {
    background: url('../../images/icons/home.png') no-repeat center center;
    background-size: contain;
    background-color: rgb(233,233,237);
    cursor: pointer;
  }

  .floating_btn{
    background: url('../../images/icons/floatingBtn.png') no-repeat center center;
    background-size: contain;
    background-color: rgb(233,233,237);
    cursor: pointer;
  }

  .btn_container{
    display: flex;
    justify-content: center;
    position:relative;
    width: 55%;
    padding: 15px;
    top: 10em;
    margin-top: -5em;
    max-height: 80px;
    z-index: 2;
  }

.button-bar{
  position: relative;
  display: flex;
  justify-content: center;
}

.floating_btn {
  display:none;
}

.blueBttn{
  //background-color:#1e90ff;
  //border: 1px #1e90ff solid; /*  #33689B */
}
.goldBttn{
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  background-color:#ffe4b5;
  //border: 1px #ffe4b5 solid;
  //color:#ffe4b5 !important;
}
