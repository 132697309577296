@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
/* UTILITIES */
* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}
:root{
    --white: #f9f9f9;
    --black: #111;
    --black2: #36384F;
    --black3: #36373f;
    --grey: #0000;
    --red: red;
}
body {
  background-color: var(--white);
  font-family: "Poppins", sans-serif;

}
a {
 text-decoration: none;
}
li {
 list-style: none;
}
/* NAVBAR STYLING STARTS */
.navigation{
  position: fixed;
  background-color: black;
  width: 100%;
  position: static;
  display: static;
}
.navbar {
 position: fixed;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 20px;
 width: 100%;
 height: 90px;
 top: 0px;
 background-color: var(--black);
 color: #fff;
 z-index: 500;
}

.menu{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  min-width: 2em;
  margin-left: 12em;
}
.nav-link{
  position: relative;
  top: 0.8em
}

.nav-links {
  display: flex;
  position: fixed;
  z-index: 501;
}

.nav-right{
  position: relative;
  top: .6em;
  margin-right: 1em;
}

.nav-links a {
 color: #fff;
}

/* LOGO */
.navbar-logo {
 margin: 10px;
 height: 3em;
 min-width: 14em;
 background-image: url('../../images/sommi-fan-token-logo-blue-thin.png');
 background-size: 75% auto;
 background-position: center;
 background-repeat: no-repeat; 
}

@include media( '<large' ) {
  .navbar-logo {
    background-image: url('../../images/sommi-fan-token-logo-circle.png');
    margin-left: -2em;
    height: 3em;
    min-width: 6.3em;
    background-size: 50% auto;
  }
  .nav-right{
    margin-right: -1em;
  }
  .nav-left{
    margin-left: -9.5em;
  }
}
/* NAVBAR MENU */
.menu {
 display: flex;
 font-size: 18px;
}
.menu li:hover {
 background-color: var(--black2);
 border-radius: 5px;
 transition: 0.3s ease;
}
.menu li {
 padding: 5px 14px;
}


/* DROPDOWN MENU */
.services {
 position: relative;
}
.dropdown {
 //background-color: rgb(1, 139, 139);
 background-color: var(--black3);
 padding: 1em 0;
 position: absolute; /*WITH RESPECT TO PARENT*/
 display: none;
 border-radius: 8px;
 top: 35px;
}
.dropdown li + li {
 margin-top: 10px;
}
.dropdown li {
 padding: 0.5em 1em;
 width: 8em;
 text-align: center;
}
.dropdown li:hover {
 background-color: var(--black2);
}
.services:hover .dropdown {
 display: block;
}
/*RESPONSIVE NAVBAR MENU STARTS*/
/* CHECKBOX HACK */
input[type=checkbox]{
 display: none;
}
/*HAMBURGER MENU*/
.hamburger {
 display: none;
 font-size: 24px;
 user-select: none;
}
@media (max-width: 500px) {
  .menu{
    width: 7.2em !important;
  }
}
/* APPLYING MEDIA QUERIES */
@media (max-width: 750px) {
.menu {
 display:none;
 position: absolute;
 width: 9em;
 background-color: black;
 right: 4em;
 top: 3.5em;
 left: 0;
 text-align: center;
 padding: 16px 0;
 cursor: pointer;
 border: 2px solid #000;
 transition: 0.5s;
 border-radius: 10px;
 box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
 z-index: 2;
}
.menu li:hover {
 display: inline-block;
 background-color:#4c9e9e;
 transition: 0.3s ease;
}
.menu li + li {
 margin-top: 12px;
}
input[type=checkbox]:checked ~ .menu{
 display: block;
}

.hamburger {
 display: flex;
 justify-content: left;
 width: 50px;
 position: relative;
 left: 10em;
 top: 0.5em;
 transform: scale(120%);
}
.hamburger b{
  position: relative;
  left: 1em;
}
.hamburger > b:hover {
  background-color: #787878;
 }

.dropdown {
 left: 50%;
 top: 30px;
 transform: translateX(35%);
}
.dropdown li:hover {
 background-color: #4c9e9e;
}
}
.menu li.button a {
  padding: 10px 15px;
  margin: 5px 0;
}
.button a {
  background: #0080ff;
  border: 1px royalblue solid;
}
.button.secondary {
  border: 0;
}
.button.secondary a {
  background: transparent;
  border: 1px #0080ff solid;
}
.button a:hover {
  text-decoration: none;
}
.button:not(.secondary) a:hover {
  background: royalblue;
  border-color: darkblue;
}


/* CSS */
.button-19 {
  appearance: button;
  background-color: #1899D6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button-19:after {
  background-clip: padding-box;
  background-color: #1CB0F6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}

/* CSS */
.button-79 {
  backface-visibility: hidden;
  background: #332cf2;
  border: 0;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display:inline-block;
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.button-79:not(:disabled):hover {
  transform: scale(1.05);
}

.button-79:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-79:focus {
  outline: 0 solid transparent;
}

.button-79:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.button-79:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
  transform: translateY(.125rem);
}
.rowclass {
  border: 1px solid black;
}
.container{
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px
}
.rowclass{
  border: 1px solid black;
  border-bottom-color: rgba(0, 0, 0, 0.65);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: separate;
  border-left-color: rgba(0, 0, 0, 0.65);
}
.tbl-col {
  padding-top: 10px;
  padding-bottom: 10px;
}
.tbl-col-btn {
  display:inline-block;
}
.green {
  color:green;
}
.blue {
  color:blue;
}
.purple {
  color:purple;
}
.grey {
  color:grey;
}
.btn-group button {
  background-color: #04AA6D; /* Green background */
  border: 1px solid green; /* Green border */
  color: white; /* White text */
  padding: 10px 24px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #3e8e41;
}

label, input, .here {
    position: relative;
    display: block;
    padding-right: 76px;
    width: 174px;
    box-sizing: border-box;
}

.here::before{
  position: absolute;
  content: 'Amountuuu';
  top: 25px;
  left: 50px;
  display: block;
  font-family: arial, helvetica, sans-serif;
  font-size: 16px;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.spanner{
  width: 100%;
  border: 1px solid grey;
  text-align: center;
  align-content: center;

}
label::after {
    content: attr(token-name);
    position: absolute;
    top: 20px;
    left: 250px;
    font-family: arial, helvetica, sans-serif;
    font-size: 20px;
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
}
.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
}
.card {
  background-color: dodgerblue;
  color: white;
  padding: 1rem;
  height: 4rem;
}

@media (min-width: 600px) {
  .cards { grid-template-columns: repeat(2, 1fr); }
}
@media (min-width: 900px) {
  .cards { grid-template-columns: repeat(3, 1fr); }
  .col-1 { grid-template-columns: repeat(2, 1fr); }
}
.col-1 {width: 50%;
border: 1px solid black;
}
.col-2 {
  width: 50%;
  border: 1px solid blue;
  text-align:center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.middle-it{
  text-align: center;
}
.row::after {
  content: "";
  clear: both;
  display: block;
  border: 1px solid red;
  padding: 2%
}
.right{
  float: right;
}
[class*="col-"] {
  float: left;
  padding: 15px;
}
.main{
  padding-top:20px;
}
.col-centered{
    margin: 0 auto;
    float: none;
}
.send-it{
  width:75%;
  color: white;
  background-color:black;
  font-size:16pt;
  height: 50px;
}
.tab-wide{
  width: 1000px;
}

.chainSelect {
  position: relative;
  display: inline-block;
  z-index: 5;
}


.chainSelect-anchor {
  max-width: 224px;
  position: relative;
  top: .15em;
  left: 1.5em;
  height: 0px;
}
.chainSelect-button {
  cursor: pointer;
  transform: scale(0.8);
  position: relative;
  top: -.3em;
  max-width: 160px;
  min-width: 160px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  min-height: 25px;
}
.chainSelect-img{
  position: relative;
  top: -1em;
  height: 50px;
  width: 50px;

}
.chainSelect-menu {
  position: absolute;
  left: 0.5em;
  top: 2.3em;
  padding: 0!important;
  background-color: white;
  min-width: 300px; 
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  color: #000;
  overflow: hidden;
  transition: left 0.3s, opacity 0.5s; 
}
.chainSelect-item {
  cursor: pointer;
  width: 100%;
  font-size: medium;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-shadow: 0.5px 1px grey;
}
.chainSelect-menu > div:hover {
  border: 3px solid yellow;
  background-color: grey;
}

.chainSelect-item img {
  width: 50px;
  height: 50px;
  margin: 5px;
}

@media (max-width: 1300px) {
.chainSelect-button{
    max-width: 85px;
    min-width: 85px;
    width: 85px;
    padding: 0;
    margin: 0;
}
.chainSelect-button img{
  min-width: 40px;
}
.chainSelect-menu {
  min-width: 75px;
  max-width: 90px !important;
  width: 75px;
}
.chainSelect-item {
  max-width: 100%;

}
.chainSelect-item img {
  position: relative;
  left: .8em;
}
}
.chainSelect-img {
  width: 45px;
  height: 40px;
}