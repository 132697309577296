
@media (max-width: 768px) {
    body{
        width: 100%;
    }  
    .section{
        width: 100%;
    }   
    
    .btn_container_col {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    
    .button-bar {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center; /* Center items vertically */
        position: fixed;
        z-index: 6;
        top: 6.9em;
        width: 4.5em;
        margin: 5px !important;
        background-color: black !important;
        border-radius: 10px;
        border: 2px solid white;
    }
    
    .mobile_bar_btn {
        width: 90%; /* Buttons take 90% of the width of the bar */
        height: auto; /* Adjust height automatically */
        margin: 5px 0; /* Add some margin between buttons */
        text-align: center;
        align-items: center;
        padding: 5px;
        height: 30px;
        border: 3px solid white;
    }
    
    .mobile_bar_home_btn {
        width: 100%; /* Ensure the home button also takes 90% width */
        margin: 5px -5px;
        position: relative;
        border: 3px solid white;
        height: 30px;
        transform: scale(0.8);
    }
    
    .floating_btn {
        display: block;
        position: sticky;
        top: 7.5em;
        margin-left: .5em;
        z-index: 100;
        height: 25px;
        width: 85px;
        box-shadow: none !important;
        border: 2px solid white;
        background-color: black;
    }
    
    .vesting{
        position: absolute;
        max-height: 100em !important;

    }
    .claiming{
        position: 'relative';
        top: '20em';
        padding-bottom: '500px' !important;
    }
    .customForm {
        margin-top: 2em; //TODO FIX ALL PANELS Stress!
        width: 90vw;
        min-height: auto;
    }
    .hero{
        padding-top: 4em;
    }

    .hidden_mobile_btn {
        display: none;
        pointer-events: none;
        opacity: 0;
      }

    .navbar{
        width:100vw;
    }
    .panel{
        justify-content: center;
        align-items: center;
        margin-top: 0em !important; 
    }
    .profilePage-container{
        width: 90% !important;
    }
      .profile-title-panel {
        justify-self: center;
        text-align: center; /* Centers text within the panel */
      }
    
      .userInfo, 
      .nftGallery,
      .friendshipPanel {
        width: 90vw !important; /* Ensures all items take up most of the viewport width */
        margin-bottom: 20px; /* Adds space between items */
      }
  
    .demoDapp-container{
        padding-top: 6em !important; 
        display: 'flex' !important;
    }
.panel-with-banner-lottery{
    width: 500px !important;
    margin-left: auto;
    margin-right: auto;
}

    .nftPage{
        width: 90vw !important;
        margin-top: 0px !important;
        margin-bottom: 2em !important;
    }

    .flex-60{
        width: 90% !important;
    }
    .media-inner{
        padding-top: 4em !important;
        width: 90vw !important;
  }
  .highScore_header{
    width: 100% !important;
    margin-left: 5% !important;
    margin-right: auto !important;
  }
  .table-page-container{
    width: 100%;
    margin-left: 5%;
  }
  .table-container{
    width: 100% !important;
    margin-left: 0px !important;
}

.card_container{
    display: flex;
    flex-direction: column;
}
.nft-image{
    width: 250px;
}

.ref-text{
    font-size: 11px !important;
}
}


@media (max-width: 510px) {
    .grid-container{
        width: 85% !important;
    }
    .nft-image{
        width: 250px !important;
    }
}