.market_grid_container {
  display: flex;
  flex-direction: column;
  min-width: 20px !important;
  max-width: 1100px;
  align-items: center;
}

.bet-card {
  position: relative;
  min-width: 420px;
  height: 410px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #141212;
  padding: 15px;
  font-family: Arial, sans-serif;
  color: #000;
  overflow-y: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px, rgba(0, 0, 0, 0.3) 0px 16px 24px;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}
#bet-card-prediction{
  height: auto;
  width: 100%;
}
#bet-options-single{
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.betcard-inner {
  width: 100%;
  margin: 0px 10px;
  padding: 10px;
  max-height: 205px;
  overflow-y: scroll;
}

.betcard-inner-prediction-page {
  margin: 0px 10px;
  padding: 10px;
  max-height: 580px;
}

.betcard-inner-single {
  margin: auto auto;
  padding: 10px;
  width: 90%;
}

.betcard-multi-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  max-height: 85px;
}
.betcard-multi-row b {
  flex: 1.8; /* First column takes minimum space required */
  text-align: left; /* Align text to the left */
}
.betcard-multi-row i {
  flex: 1; /* Second column centered */
  text-align: center;
}
.bet-options {
  flex: 1; /* Third column (buttons) aligned at the end */
  display: flex;
  justify-content: flex-end; /* Aligns buttons to the right */
  gap: 10px; /* Spacing between buttons */
}

.betcard-multi-row:hover{
  background-color: #d9e0e2;
}
/* hide scrollbar for chrome, safari and opera */
.bet-card::-webkit-scrollbar {
  display: none;
}

/* Container for tabs */
.betcard-tabs {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #333;
  margin-top: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  height: 100px;
}

/* Individual tab styles */
.betcard-tab {
  padding: 8px 12px;
  cursor: pointer;
  transition: color 0.3s, border-bottom 0.3s;
  color: #ccc;
  flex: 0 0 auto;
  margin-right: 10px;
  text-align: center;
  font-size: 16px;
}

/* Active tab styles */
.betcard-tab.active {
  color: #42aaff;
  border-bottom: 2px solid #42aaff;
}

/* Hover effect for tabs */
.betcard-tab:hover {
  color: #fff;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 8px 12px;
  cursor: pointer;
  transition: color 0.3s;
  color: #ccc;
  border: none;
  background: none;
  font-size: 14px;
  text-align: center;
  outline: none;
}

.dropdown-button:hover {
  color: #fff;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  min-width: 150px;
}

.dropdown-content .dropdown-tab {
  color: #ccc;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-content .dropdown-tab:hover {
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content .active {
  color: #42aaff;
}

.bet-card-more-details {
  max-width: 850px;
  min-height: 400px;
}

.bet-card-more-details h3 {
  color: black;
  font-size: 24px;
}

.bet-header {
  display: flex;
  align-items: center;
  height: 15%;
}

.bet-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.bet-title-anchor {
  color: black;
}

.bet-title {
  margin-left: 5px;
  font-size: 15px;
  height: 80px;
  font-weight: bold;
  overflow: hidden;
  max-height: 65px;
}

.bet-content {
  margin-top: 10px;
  max-height: 125px;
  opacity: 2;
}

.bet-content:-webkit-scrollbar {
  display: none;
}

.bet-chart {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  gap: 5%;
}

.bet-chart-bar {
  position: relative;
  margin-left: 5%;
  margin-bottom: 20px;
  width: 60px;  /* Set the width of the donut */
  height: 60px; /* Setthe height (same as width for a circle) */
  border-radius: 50%; /* Make it circular */
  background-color: #4d5968; /* The background color for the unfilled part of the donut */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bet-chart-fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    #fa8231 calc(var(--percentage) * 1%),
    transparent 0
  ); /* This creates the filled portion based on the percentage */
  position: relative;
}

.bet-chart-fill::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: white; /* Color of the inner hole of the donut */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bet-options {
  display: flex;
  gap: 5px;
}

.bet-option {
  padding: 8px 12px;
  width: 60px;
  height: 30px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.bet-option:hover {
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 6px;
}

.bet_option_text {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  font-size: 15px;
  text-align: start;
  color: #333;
}

.bet_option_text_small {
  font-size: 10pt;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 1;
  margin: auto 0px;
}

.bet-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  gap: 25px;
  justify-content: center;
}

.bet-footer {
  position: absolute;
  bottom: 12px;
  align-items: center;
  max-height: 30px;
  margin-top: 10px;
  padding: 5px;
  overflow: hidden;
}

.footer-data {
  display: flex; /*needs  changing */
  width: 100%;
  gap: 20px;
  margin: auto;
  justify-content: space-between;
  font-size: 12px;
  height: 25px;
}

.bet-icons i {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.bet_btn {
  width: 90%;
}

.market_search_bar {
  width: 1250px;
  padding: 10px 15px;
  margin: 15px;
  font-size: 1rem;
  border: 2px solid #00A3E8;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #2b3a4b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.3s ease;
}

.market_search_bar::placeholder {
  color: #888888;
  opacity: 0.8;
}

.market_search_bar:focus {
  border-color: #ffab4c;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.prediction_title {
  width: 60vw;
}

.input-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.bet-input {
  background-color: #1c2b37;
  color: white;
  border: none;
  width: 100px;
  padding: 5px;
  margin-right: 10px;
  text-align: center;
}

.bet-input:focus {
  outline: 2px solid #42aaff;
  background-color: #f0f8ff;
}

.slider-container {
  width: 100%;
  margin: 10px;
}

.bet-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin: 0;
  padding: 0;
}

.bet-slider:hover {
  opacity: 1;
}

.bet-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bet-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bet-button {
  flex-direction: column;
  justify-content: center;
}

.bet-button span {
  display: block;
  font-size: 12px;
  color: #e0e0e0;
}

.close-button {
  font-size: 18px;
  font-weight: bold;
  color: #ff4d4d;
  cursor: pointer;
  background-color: #fff7f7;
  border: 2px solid #ff4d4d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 5px;
  right: 5px;
}

.close-button:hover {
  transform: rotate(15deg) scale(1.1);
  background-color: #ff4d4d;
  color: #fff7f7;
}

.outcomes {
  display: flex;
  justify-content: space-between;
}

.betcard-input-controller {
  display: none;

}

.betcard-input-controller-active {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid black;
  width: 100%;
  transition: opacity 0.5s ease-in;
  margin: auto
}

.betcard-select-container {
  margin: 0px 0px;
  padding: 0px 5px;
}

.betcard-label {
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  color: #000;
  width: auto;
  line-height: 1.2;
}

/* Main container for the component */
.prediction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.market-container{
  height: 85%
}

.prediction-container-grid{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Toggle button section */
.prediction-toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.prediction-toggle-button {
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #007bff;
  background-color: #e3f2fd; /* Pale blue background */
  color: #007bff;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.prediction-toggle-button:hover {
  background-color: #007bff;
  color: white;
}

/* Info box styling */
.prediction-info {
  width: 99%;
  height: 100%;
  padding: 15px;
  background-color: #e3f2fd; /* Pale blue for info box */
  border-radius: 8px;
  margin: 20px 0px;
  text-align: start;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.prediction-info-item-flex {
  display: flex;
  text-align: start;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.prediction-info-item{
  text-align: start;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

/* Section for adding */
.prediction-add-section {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  height: 100%;
}

.smaller{
  font-size: 10pt;
}

.word-wrapper{
  word-wrap: break-word;
  max-width: 600px;
}

/* Select dropdown styling */
.betcard-select {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #007bff;
  border-radius: 6px;
  background-color: #f1f9ff;
  color: #333;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  transition: border-color 0.2s ease;
}

.betcard-select:focus {
  border-color: #0056b3;
}

/* Multi-row option styling */
.betcard-multi-rows {
  width: 100%;
  margin-top: 15px;
}

.betcard-multi-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  max-height: 85px;
}

/* Yes/No button styling */
.bet-options .button {
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.button.green {
  background-color: #4caf50;
  color: white;
}

.button.red {
  background-color: #f44336;
  color: white;
}

.button.green:hover {
  background-color: #388e3c;
}

.button.red:hover {
  background-color: #d32f2f;
}

/* Submit section for add */
.prediction-submit-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin: auto;
}

#market_submit_button_wrapper{
  position: absolute;
  display: flex;
  bottom: 25px;
  height: 70px;
  width: 90%;
}
#prediction_submit_button_wrapper{
  width: 100%;
}
.button.bet_btn_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px auto;
  padding: 5px;
  font-size: 18px;
  background-color: rgb(38, 115, 216);
  color: white;
  transition: background-color 0.2s ease;
}
.bet_btn_sub:hover {
  background-color: #42aaff;
  transform: scale(1.05);
}
.you-frame{
  grid-column: span 2;
  margin: auto;
  max-width: 90%;
  align-self: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: none;
  border-radius: 10px;
}
@media (max-width: 1300px) {
  .bet-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .market_search_bar {
    width: 850px !important;
  }
}

@media (max-width: 900px) {
  .bet-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .market_search_bar {
    width: 400px !important;
  }
  .bet-card-more-details {
    max-width: 550px;
  }
}

@media (max-width: 600px) {
  .bet-list {
    grid-template-columns: 1fr;
  }

  .bet-card {
    width: 95%;
    padding: 10px;
  }

  .betcard-inner-single {
    padding: 5px;
  }

  .market_search_bar {
    width: 300px;
    font-size: 0.9rem;
  }
}
