//Imported
html{
    line-height:1.15;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%
}
body{
    margin:0
}
article,aside,footer,header,nav,section{
    display:block
}
h1{
    font-size:2em;
    margin:0.67em 0
}
figcaption,figure,main{
    display:block;

}
figure{
    margin:1em 40px
}
hr{
    box-sizing:content-box;
    height:0;
    overflow:visible
}
pre{
    font-family:monospace, monospace;
    font-size:1em
}
a{
    background-color:transparent;
    -webkit-text-decoration-skip:objects
}
abbr[title]{
    border-bottom:none;
    text-decoration:underline;
    -webkit-text-decoration:underline dotted;
    text-decoration:underline dotted
}
b,strong{
    font-weight:inherit
}
b,strong{
    font-weight:bolder
}
code,kbd,samp{
    font-family:monospace, monospace;
    font-size:1em
}
dfn{
    font-style:italic
}
mark{
    background-color:#ff0;
    color:#000
}
small{
    font-size:80%
}
sub,sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline
}
sub{
    bottom:-0.25em
}
sup{
    top:-0.5em
}
audio,video{
    display:inline-block
}
audio:not([controls]){
    display:none;
    height:0
}
img{
    border-style:none
}
svg:not(:root){
    overflow:hidden
}
button,input,optgroup,select,textarea{
    font-family: Inter, Inter Placeholder, sans-serif;
    font-size:100%;
    line-height:1.15;
    margin:0
}
button,input{
    overflow:visible
}
button,select{
    text-transform:none
}
button,html [type="button"],[type="reset"],[type="submit"]{
    -webkit-appearance:button
}
button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{
    border-style:none;
    padding:0
}
button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring{
    outline:1px dotted ButtonText
}
fieldset{
    padding:0.35em 0.75em 0.625em
}
legend{
    box-sizing:border-box;
    color:inherit;
    display:table;
    max-width:100%;
    padding:0;
    white-space:normal
}
progress{
    display:inline-block;
    vertical-align:baseline
}
textarea{
    overflow:auto
}
[type="checkbox"],[type="radio"]{
    box-sizing:border-box;
    padding:0
}
[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button{
    height:auto
}
[type="search"]{
    -webkit-appearance:textfield;
    outline-offset:-2px
}
[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration{
    -webkit-appearance:none
}
::-webkit-file-upload-button{
    -webkit-appearance:button;
    font:inherit
}
details,menu{
    display:block
}
summary{
    display:list-item
}
canvas{
    display:inline-block
}
template{
    display:none
}
[hidden]{
    display:none
}
html{
    box-sizing:border-box
}
*,*:before,*:after{
    box-sizing:inherit
}
body{
    background:#06101F;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased
}
hr{
    border:0;
    display:block;
    height:1px;
    background:#273C5A;
    background:linear-gradient(to right, rgba(39,60,90,0.1) 0, rgba(39,60,90,0.6) 50%, rgba(39,60,90,0.1) 100%);
    margin-top:24px;
    margin-bottom:24px
}
ul,ol{
    margin-top:0;
    margin-bottom:24px;
    padding-left:24px
}
ul{
    list-style:disc
}
ol{
    list-style:decimal
}
li>ul,li>ol{
    margin-bottom:0
}
dl{
    margin-top:0;
    margin-bottom:24px
}
dt{
    font-weight:700
}
dd{
    margin-left:24px;
    margin-bottom:24px
}
img{
    height:auto;
    max-width:100%;
    vertical-align:middle
}
figure{
    margin:24px 0
}
figcaption{
    font-size:16px;
    line-height:24px;
    padding:8px 0
}
img,svg{
    display:block
}
table{
    border-collapse:collapse;
    margin-bottom:24px;
    width:100%
}
tr{
    border-bottom:1px solid #273C5A
}
th{
    text-align:left
}
th,td{
    padding:10px 16px
}
th:first-child,td:first-child{
    padding-left:0
}
th:last-child,td:last-child{
    padding-right:0
}
html{
    font-size:20px;
    line-height:30px
}
body{
    color:#7487A3;
    font-size:1rem
}
body,button,input,select,textarea{
    font-family: Inter, Inter Placeholder, sans-serif;
}
a{
    color:#F9425F;
    text-decoration:none
}
a.white-link{
    color:#ffF;
    text-decoration:none
}
a:hover,a:active{
    outline:0;
    text-decoration:underline
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    clear:both;
    color:#fff;
    font-family: Inter, Inter Placeholder, sans-serif;
    font-weight:700
}
h1,.h1{
    font-size:44px;
    line-height:56px;
    letter-spacing:0px
}
.features{
  background-color:#fef200;
}
.section-title-2{
  color:#000;
}
@media (min-width: 641px){
    h1,.h1{
        font-size:48px;
        line-height:62px;
        letter-spacing:0px
    }
}
h2,.h2{
    font-size:40px;
    line-height:52px;
    letter-spacing:0px
}
@media (min-width: 641px){
    h2,.h2{
        font-size:44px;
        line-height:56px;
        letter-spacing:0px
    }
}
h3,.h3,blockquote{
    font-size:24px;
    line-height:34px;
    letter-spacing:-0.1px
}
h4,h5,h6,.h4,.h5,.h6{
    font-size:20px;
    line-height:30px;
    letter-spacing:-0.1px
}
@media (max-width: 640px){
    .h1-mobile{
        font-size:44px;
        line-height:56px;
        letter-spacing:0px
    }
    .h2-mobile{
        font-size:40px;
        line-height:52px;
        letter-spacing:0px
    }
    .h3-mobile{
        font-size:24px;
        line-height:34px;
        letter-spacing:-0.1px
    }
    .h4-mobile,.h5-mobile,.h6-mobile{
        font-size:20px;
        line-height:30px;
        letter-spacing:-0.1px
    }
}

.text-sm{
    font-size:18px;
    line-height:27px;
    letter-spacing:-0.1px
}
.text-xs{
    font-size:16px;
    line-height:24px;
    letter-spacing:-0.1px
}
h1,h2,.h1,.h2{
    margin-top:15px;
    margin-bottom:16px
}
h3,.h3{
    margin-top:36px;
    margin-bottom:12px
}
h4,h5,h6,.h4,.h5,.h6{
    margin-top:24px;
    margin-bottom:4px
}
p{
    margin-top:0;
    margin-bottom:24px
}
dfn,cite,em,i{
    font-style:italic
}
blockquote{
    color:#556B8B;
    font-style:italic;
    margin-top:24px;
    margin-bottom:24px;
    margin-left:24px
}
blockquote::before{
    content:"\201C"
}
blockquote::after{
    content:"\201D"
}
blockquote p{
    display:inline
}
address{
    color:#7487A3;
    border-width:1px 0;
    border-style:solid;
    border-color:#273C5A;
    padding:24px 0;
    margin:0 0 24px
}
pre,pre h1,pre h2,pre h3,pre h4,pre h5,pre h6,pre .h1,pre .h2,pre .h3,pre .h4,pre .h5,pre .h6{
    font-family:"Courier 10 Pitch", Courier, monospace;
    font-family: Inter, Inter Placeholder, sans-serif;
}
pre,code,kbd,tt,var{
    background:#1D304B
}
pre{
    font-size:16px;
    line-height:24px;
    margin-bottom:1.6em;
    max-width:100%;
    overflow:auto;
    padding:24px;
    margin-top:24px;
    margin-bottom:24px
}
code,kbd,tt,var{
    //font-family:Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    font-family: Inter, Inter Placeholder, sans-serif;
    font-size:16px;
    padding:2px 4px
}
abbr,acronym{
    cursor:help
}
mark,ins{
    text-decoration:none
}
small{
    font-size:18px;
    line-height:27px;
    letter-spacing:-0.1px
}
b,strong{
    font-weight:700
}
button,input,select,textarea,label{
    font-size:28px;
    line-height:30px
}
.container,.container-sm{
    width:100%;
    margin:0 auto;
    padding-left:16px;
    padding-right:16px;
    padding-bottom: 75px;
}
@media (min-width: 481px){
    .container,.container-sm{
        padding-left:24px;
        padding-right:24px
    }
}
.container{
    max-width:1128px
}

.container .container-sm{
    max-width:800px;
    padding-left:0;
    padding-right:0
}
.screen-reader-text{
    clip:rect(1px, 1px, 1px, 1px);
    position:absolute !important;
    height:1px;
    width:1px;
    overflow:hidden;
    word-wrap:normal !important
}
.screen-reader-text:focus{
    border-radius:2px;
    box-shadow:0 0 2px 2px rgba(0,0,0,0.6);
    clip:auto !important;
    display:block;
    font-size:14px;
    letter-spacing:0px;
    font-weight:700;
    line-height:16px;
    text-decoration:none;
    text-transform:uppercase;
    background-color:#06101F;
    color:#F9425F !important;
    border:none;
    height:auto;
    left:8px;
    padding:16px 32px;
    top:8px;
    width:auto;
}
.list-reset{
    list-style:none;
    padding:0
}
.text-left{
    text-align:left
}
.text-center{
    text-align:center
}
.text-right{
    text-align:right
}
.text-primary{
    color:#F9425F
}
.text-secondary{
    color:#47A1F9
}
.has-top-divider{
    position:relative;
    background:black;
}
.has-top-divider::before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    display:block;
    height:1px;
     /* background:#273C5A; */
    background:linear-gradient(to right, rgba(39,60,90,0.1) 0, rgba(39,60,90,0.6) 50%, rgba(39,60,90,0.1) 100%);

}
.has-bottom-divider{
    position:relative
}
.has-bottom-divider::after{
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    display:block;
    height:1px;
    background:#273C5A;
    background:linear-gradient(to right, rgba(39,60,90,0.1) 0, rgba(39,60,90,0.6) 50%, rgba(39,60,90,0.1) 100%)
}
.m-0{
    margin:0
}
.mt-0{
    margin-top:0
}
.mr-0{
    margin-right:0
}
.mb-0{
    margin-bottom:0
}
.ml-0{
    margin-left:0
}
.m-8{
    margin:8px
}
.mt-8{
    margin-top:8px
}
.mr-8{
    margin-right:8px
}
.mb-8{
    margin-bottom:8px
}
.ml-8{
    margin-left:8px
}
.m-16{
    margin:16px
}
.mt-16{
    margin-top:16px
}
.mr-16{
    margin-right:16px
}
.mb-16{
    margin-bottom:16px
}
.ml-16{
    margin-left:16px
}
.m-24{
    margin:24px
}
.mt-24{
    margin-top:24px
}
.mr-24{
    margin-right:24px
}
.mb-24{
    margin-bottom:24px
}
.ml-24{
    margin-left:24px
}
.m-32{
    margin:32px
}
.mt-32{
    margin-top:32px
}
.mr-32{
    margin-right:32px
}
.mb-32{
    margin-bottom:32px
}
.ml-32{
    margin-left:32px
}
.m-40{
    margin:40px
}
.mt-40{
    margin-top:40px
}
.mr-40{
    margin-right:40px
}
.mb-40{
    margin-bottom:40px
}
.ml-40{
    margin-left:40px
}
.m-48{
    margin:48px
}
.mt-48{
    margin-top:48px
}
.mr-48{
    margin-right:48px
}
.mb-48{
    margin-bottom:48px
}
.ml-48{
    margin-left:48px
}
.m-56{
    margin:56px
}
.mt-56{
    margin-top:56px
}
.mr-56{
    margin-right:56px
}
.mb-56{
    margin-bottom:56px
}
.ml-56{
    margin-left:56px
}
.m-64{
    margin:64px
}
.mt-64{
    margin-top:64px
}
.mr-64{
    margin-right:64px
}
.mb-64{
    margin-bottom:64px
}
.ml-64{
    margin-left:64px
}
.p-0{
    padding:0
}
.pt-0{
    padding-top:0
}
.pr-0{
    padding-right:0
}
.pb-0{
    padding-bottom:0
}
.pl-0{
    padding-left:0
}
.p-8{
    padding:8px
}
.pt-8{
    padding-top:8px
}
.pr-8{
    padding-right:8px
}
.pb-8{
    padding-bottom:8px
}
.pl-8{
    padding-left:8px
}
.p-16{
    padding:16px
}
.pt-16{
    padding-top:16px
}
.pr-16{
    padding-right:16px
}
.pb-16{
    padding-bottom:16px
}
.pl-16{
    padding-left:16px
}
.p-24{
    padding:24px
}
.pt-24{
    padding-top:24px
}
.pr-24{
    padding-right:24px
}
.pb-24{
    padding-bottom:24px
}
.pl-24{
    padding-left:24px
}
.p-32{
    padding:32px
}
.pt-32{
    padding-top:32px
}
.pr-32{
    padding-right:32px
}
.pb-32{
    padding-bottom:32px
}
.pl-32{
    padding-left:32px
}
.p-40{
    padding:40px
}
.pt-40{
    padding-top:40px
}
.pr-40{
    padding-right:40px
}
.pb-40{
    padding-bottom:40px
}
.pl-40{
    padding-left:40px
}
.p-48{
    padding:48px
}
.pt-48{
    padding-top:48px
}
.pr-48{
    padding-right:48px
}
.pb-48{
    padding-bottom:48px
}
.pl-48{
    padding-left:48px
}
.p-56{
    padding:56px
}
.pt-56{
    padding-top:56px
}
.pr-56{
    padding-right:56px
}
.pb-56{
    padding-bottom:56px
}
.pl-56{
    padding-left:56px
}
.p-64{
    padding:64px
}
.pt-64{
    padding-top:64px
}
.pr-64{
    padding-right:64px
}
.pb-64{
    padding-bottom:64px
}
.pl-64{
    padding-left:64px
}
.sr .has-animations .is-revealing{
    visibility:hidden
}

.input,.textarea{
    background-color:#fff;
    border-width:1px;
    border-style:solid;
    border-color:#273C5A;
    border-radius:2px;
    color:#7487A3;
    max-width:100%;
    width:100%
}
.input::-moz-placeholder, .textarea::-moz-placeholder{
    color:#556B8B
}
.input::placeholder,.textarea::placeholder{
    color:#556B8B
}
.input::-ms-input-placeholder,.textarea::-ms-input-placeholder{
    color:#556B8B
}
.input:-ms-input-placeholder,.textarea:-ms-input-placeholder{
    color:#556B8B
}
.input:hover,.textarea:hover{
    border-color:#1f3048
}
.input:active,.input:focus,.textarea:active,.textarea:focus{
    outline:none;
    border-color:#273C5A
}
.input[disabled],.textarea[disabled]{
    cursor:not-allowed;
    background-color:#1D304B;
    border-color:#1D304B
}
.input{
    -moz-appearance:none;
    -webkit-appearance:none;
    font-size:16px;
    letter-spacing:-0.1px;
    line-height:20px;
    padding:13px 16px;
    height:48px;
    box-shadow:none
}
.input .inline-input{
    display:inline;
    width:auto
}
.textarea{
    display:block;
    min-width:100%;
    resize:vertical
}
.textarea .inline-textarea{
    display:inline;
    width:auto
}
.field-grouped>.control:not(:last-child){
    margin-bottom:8px
}
@media (min-width: 641px){
    .field-grouped{
        display:flex
    }
    .field-grouped>.control{
        flex-shrink:0
    }
    .field-grouped>.control.control-expanded{
        flex-grow:1;
        flex-shrink:1
    }
    .field-grouped>.control:not(:last-child){
        margin-bottom:0;
        margin-right:8px
    }
}
.button::before{
    border-radius:2px
}
.button-shadow{
    position:relative
}
.button-shadow::before{
    content:'';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    box-shadow:0 8px 24px rgba(6,16,31,0.25);
    mix-blend-mode:multiply;
    transition:box-shadow .15s ease
}
.button-shadow:hover::before{
    box-shadow:0 8px 24px rgba(6,16,31,0.35)
}
.button-sm{
    padding:8px 24px;
    height:32px
}
.button-sm.button-shadow::before{
    box-shadow:0 4px 16px rgba(6,16,31,0.25)
}
.button-sm.button-shadow:hover::before{
    box-shadow:0 4px 16px rgba(6,16,31,0.35)
}
.button-primary{
    background-color: #4CAF50;
}
.button-primary:hover{
    background:#f94763
}
.button-primary.button-shadow::before{
    box-shadow:0 8px 16px rgba(249,66,95,0.25);
    mix-blend-mode:normal
}
.button-primary.button-shadow:hover::before{
    box-shadow:0 8px 16px rgba(249,66,95,0.35)
}
.button-primary .button-sm.button-shadow::before{
    box-shadow:0 4px 16px rgba(249,66,95,0.25)
}
.button-primary .button-sm.button-shadow:hover::before{
    box-shadow:0 4px 16px rgba(249,66,95,0.35)
}
.button-block{
    display:flex
}
.site-header{
    position:relative;
    padding:14px 0

}

.site-header-inner{
    position:relative;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.header-links{
    display:inline-flex
}
.header-links li{
    display:inline-flex
}
.header-links a:not(.button){
    font-size:16px;
    line-height:24px;
    letter-spacing:-0.1px;
    font-weight:700;
    text-transform:uppercase;
    text-decoration:none;
    line-height:16px;
    padding:8px 24px
}
@media (min-width: 641px){
    .site-header::before{
        display:none
    }
}
.hero{
    text-align:center;
    padding-top:48px;
    padding-bottom:24px;
}
.hero-copy{
    position: relative;
    font-size:26px;
    font-weight: bold;
    color: black;
}
.hero-paragraph{
    margin-bottom:32px
}
.hero-app{
    position:relative
}
.hero-app-illustration{
    position:absolute;
    top:-358px;
    left:calc(50% - 436px);
    pointer-events:none
}
.hero-app-dots{
    display:none;
    position:absolute
}
.hero-app-dots-1{
    top:15px;
    left:-108px
}
.hero-app-dots-2{
    bottom:100px;
    left:320px
}
.hero-cta{
    max-width:400px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:40px
}
@media (max-width: 639px){
    .hero-cta .button{
        display:flex
    }
    .hero-cta .button+.button{
        margin-top:16px
    }
    .hero-shape-top{
        display:none
    }
}
@media (min-width: 641px){
    .hero{
        position:relative;
        text-align:left;
        padding-top:56px;
        padding-bottom:12px;

    }
    .hero-inner{
        display:flex;
        justify-content:space-between
    }
    .hero-copy{
        padding-top:88px;
        padding-right:48px;
        min-width:448px;
        max-width:588px
    }
    .hero-title{
        margin-bottom:24px
    }
    .hero-paragraph{
        margin-bottom:40px
    }
    .hero-app-illustration{
        left:-257px
    }
    .hero-app-dots{
        display:block
    }
    .device-mockup{
        max-width:none
    }
    .hero-cta{
        margin:0
    }
    .hero-cta .button{
        min-width:170px
    }
    .hero-cta .button:first-child{
        margin-right:16px
    }
}
.text-box{
  height:100%;
  border:3px solid black;
  border-radius: 10px;
  background-color: white;
  padding-left:  30px;
  padding-right:  30px;
  padding-bottom:  30px;
  padding-top:  30px;
  color:black;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}
.features .section-title{
    margin-bottom:48px
}

.features-wrap{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-evenly;
    margin-right:-30px;
    margin-left:-30px
}
.features-wrap:first-of-type{
    margin-top:-16px
}
.features-wrap:last-of-type{
    margin-bottom:-16px
}
.feature{
    padding:16px 30px;
    width:380px;
    max-width:380px;
    flex-grow:1
}
.feature-inner{
    height:100%;
    border:3px solid black;
    border-radius: 10px;
    background-color: white;
    padding: 30px;
    color:black;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.feature-title{
    font-family: Inter, Inter Placeholder, sans-serif;
    font-weight:500

}
.feature-title-2{
      color:black;
      font-size: 20px;
}
.feature-icon{
  border:3px solid black;
}
.features{
  background-color:#fef200;
}
.section-title-2{
    color:black;

}
@media (min-width: 641px){
    .features{
        position:relative;
        background-color:#fef200;
        color:black;
    }
    .features .section-inner{
        padding-bottom:100px
    }
    .features .section-title{
        position:relative;
        margin-bottom:80px
    }

    .features-wrap:first-of-type{
        margin-top:-32px
    }
    .features-wrap:last-of-type{
        margin-bottom:-32px
    }
    .feature{
        padding:32px 30px
    }
}
.media{
  background-color: #23b14d;
}
.media .section-inner{
    padding-bottom:104px
}
.media .section-paragraph{
    margin-bottom:48px
}
.media-canvas{
    position:relative;
    border:3px solid black;
    border-radius: 10px;
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:  20px;
    padding-bottom: 20px;
    color:black;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.media-canvas svg{
    position:relative;
    width:100%;
    height:autonew
}

.media-control{
    position:absolute;
    top:calc(50% - 24px);
    left:calc(50% - 24px);
    cursor:pointer
}
.media-control svg{
    width:48px;
    height:48px;
    overflow:visible
}
@media (min-width: 641px){
    .media .section-inner{
        padding-bottom:144px
    }
    .media .section-paragraph{
        padding-left:72px;
        padding-right:72px;
        margin-bottom:80px
    }
    .media-canvas::before{
        width:530px;
        height:380px;
        bottom:-128px;
        left:calc(50% - 275px);
        background-size:530px 380px
    }
    .media-control{
        top:calc(50% - 48px);
        left:calc(50% - 48px)
    }
    .media-control svg{
        width:96px;
        height:96px
    }
}.newsletter{
  background-color: #00a3e8 ;
}
.newsletter .section-paragraph{
    margin-bottom:32px
}
.newsletter-form{
    max-width:440px;
    margin:0 auto
}
@media (min-width: 641px){
    .newsletter .section-paragraph{
        margin-bottom:40px;
        padding-left:72px;
        padding-right:72px
    }
}
.is-boxed{
    background:#0b1524
}
.body-wrap{
    //background:#06101F;
    background:#000;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    min-height:100vh


}
.boxed-container{
    max-width:1440px;
    margin:0 auto !important;
    box-shadow:0 16px 48px rgba(6,16,31,0.5);
    overflow-wrap: break-word;

}
main{
    flex:1 0 auto
}

.section-inner{
    position:relative;
    padding-top:48px;
    padding-bottom:48px
}
@media (min-width: 641px){
    .section-inner{
        padding-top:80px;
        padding-bottom:80px
    }
}
.site-footer{
    position:relative;
    font-size:14px;
    line-height:20px;
    letter-spacing:0px;
    color:white; /* color:#556B8B; */
    background-color: black;
    margin-top: 0em !important;
}

.site-footer a{
    color:#556B8B;
    text-decoration:none
}
.site-footer a:hover,.site-footer a:active{
    text-decoration:underline
}
.site-footer-inner{
    position:relative;
    display:flex;
    flex-wrap:wrap;
    padding-top:48px;
    padding-bottom:48px
}
.footer-brand,.footer-links,.footer-social-links,.footer-copyright{
    flex:none;
    width:100%;
    display:inline-flex;
    justify-content:center
}
.footer-brand,.footer-links,.footer-social-links{
    margin-bottom:24px
}
.footer-links li+li,.footer-social-links li+li{
    margin-left:16px
}
.footer-social-links li{
    display:inline-flex
}
.footer-social-links li a{
    padding:8px
}
@media (min-width: 641px){
    .site-footer{
        margin-top:-2px
    }
    .site-footer-inner{
        justify-content:space-between;
        padding-top:64px;
        padding-bottom:64px
    }
    .footer-brand,.footer-links,.footer-social-links,.footer-copyright{
        flex:50%
    }
    .footer-brand,.footer-copyright{
        justify-content:flex-start
    }
    .footer-links,.footer-social-links{
        justify-content:flex-end
    }
    .footer-links{
        order:1;
        margin-bottom:0
    }
}
