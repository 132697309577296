.nft-lightbox-container {
    display: grid;
    color: #fff;
    font-size: 28px;
    text-align: left;
    animation: fadeIn 0.5s ease-in-out;
    box-shadow: none;
    background-image: url('../../assets/images/background/sphereGlow.gif');
    background-size: cover;
    background-position: center;
    grid-template-areas:
        "col1 col2"
        "fullRow fullRow";
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto; /* Adjust the height of the rows */
    transform: scale(0.6);
    overflow: hidden;
}
.closedBox-img{
    width: 100%;
    max-width: 600px;
    border: 2px black solid;
}
.nft-lightbox-col1.panel {
    grid-area: col1;
    word-wrap: break-word;
    background-color: rgba(255, 255, 255, 0.7);
    margin-right: 0px;
}

.nft-lightbox-col2.panel{
    background-color: rgba(255, 255, 255, 0.7);
    grid-area: col2;
    display: flex;
    justify-content: flex-end;
}

.nft-lightbox-row2.panel{
    grid-area: fullRow;
    background-color: rgba(255, 255, 255, 0.7);
    margin-top: 0px;
}

.black-title{
    text-align: center;
    color: black;
}
.flex-60{
    display: flex;
    width: 65%;
}

.nftPage{
    position: 'relative';
    top: '20em';
    padding-bottom: '500px' !important;
    word-break: break-word;
    padding-top: 0px;
}
.nftInfo{
  border: 2px solid #000;
  margin-top:20px;
  //text-align: center;
  padding: 20px;
  font-size: 12pt;
  border-radius: 12px;
  //background-color: #ddd;
  border-color: #aaa;
  background-color: lightGrey;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);

  //align-items: center;
  //justify-content: center;
}
.nftNum{
  width:100px;
}
label {

}
