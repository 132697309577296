body{
    font-family: 'Work Sans', sans-serif;
}
.faq-heading{
    border-bottom: #777;
    padding: 20px 60px;
}
.faq-container{
display: flex;
justify-content: center;
flex-direction: column;

}
.hr-line{
  width: 60%;
  margin: auto;

}
/* Style the buttons that are used to open and close the faq-page body */
.faq-page {
    /* background-color: #eee; */
    color: #000;
    cursor: pointer;
    padding: 30px 20px;
    width: 100%;
    border: 2px solid #000;
    background-color: #fff;
    outline: none;
    transition: 0.4s;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;


}
.faq-body{
    margin: auto;
    /* text-align: center; */
   width: 100%; /*50% */

}
/* Style the faq-page panel. Note: hidden by default */
.faq-body {
    padding: 20px 20px;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    color:black;
    border: 2px solid #000;
    font-size: 24px;

box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.faq-page:after {
    content: '\02795';
    /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
}
