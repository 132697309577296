.popup-container {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    color: #000;
    border: 2px solid #141212;
    outline: none;
    transition: 0.4s;
    border-radius: 10px;
  }
  
  .popup-content {
    background-color: white;
    border-radius: 5px;
    position: relative;
  }
  
  /*
.error-screen{
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: black;
    color: red; 
    height: 0%;
    width: 100%;
    z-index: 99;
}
.error-container{
    position: fixed;
    background-color: green;
    top: 11em; 
    opacity:90%;
    left: 50%;
    transform: translateX(-50%);
    font-size:14px;
    letter-spacing:0px;
    font-weight:700;
    line-height:16px;
    text-decoration:none !important;
    text-transform:uppercase;
    color: black !important; //#fff !important;
    border: 2px solid black;
    border-radius:10px;
    cursor:pointer;
    justify-content:center;
    padding:16px 32px;
    margin-left: 10px; 
    margin-right: 10px;
    height:48px;
    text-align:center;
    white-space:nowrap;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.error-text{
    color: #ff0000; 
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    z-index: 99;
}

.error-slider{
    position: relative;
    //top: 0.2em;
    padding-top:45px;
    width: 100%;
    margin-bottom:-30px;
    background-color:black;
}
*/