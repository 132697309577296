@media (min-width: 768px) and (max-width: 1520px) {
    .profilePage-container {
        width: 75% !important;
      }
}

@media (max-width: 1300px){
    .NFT-panel2{
        max-height: 450px;
        max-width: 100% !important
    }
    .scrollable-gallery-vert img{
        height: auto;
        width: auto;
    }
    .nft-image{
        width: 320px;
        height: auto;
    }
    .friendship-panel-inner{
        display: flex !important;
        flex-direction: column !important;
        width: 100%;
        margin: auto;
    }
    .ProfilePage-topRow{
        display: flex;
        flex-direction: column;
        height: auto !important;
    }
    .grid-container{
        display: flex !important;
        flex-direction: column;
        margin: 0px !important;
        justify-content: center !important;

    } 
    .grid-item1{
        display: flex;
        justify-content: center;
    }
    .grid-item2{
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
        margin: auto;
    }
    .attribute-panel{
        width: 100%;
    }
    .custom-alert{
        right: 5% !important;
    }
}
