.panel h4{
    color: black;
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
}

.grid-container {
    box-sizing: border-box;
    margin: 10px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as per your design */
  }

.token-panel-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.panel{
    margin: 35px;
    color: #000;
    padding: 10px;
    border: 2px solid #141212;
    background-color: white;
    outline: none;
    transition: 0.4s;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.panel-with-banner{
    padding: 0px;
    border-top-left-radius: 20px; /* Rounds the top-left corner */
    border-top-right-radius: 20px; /* Rounds the top-right corner */
    max-width: 1000px;
    min-width: 800px;
    margin: 35px auto;
}
.general-banner-img{
    width: 100%;
    height: 250px;
    border-top-left-radius: 20px; /* Rounds the top-left corner */
    border-top-right-radius: 20px; /* Rounds the top-right corner */
    background-size: cover; /* This makes the image cover the entire div */
    background-position: center; /* This centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
}
.lottery-banner-img{
    background-image: url('../../assets/images/banners/Sommi-Fan-Lottery-bg-demo.jpg');
}
.markets-banner-img{
    background-image: url('../../assets/images/banners/lottery1.png');
}
.user-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
}

.user-panel-img{
    width: 50px;
}
.user-panel-inner{
    position: 'relative';
    justify-content: center;
    width: 100%;
    display: flex;
    text-align: center;
    border: 1px solid black;
    background-color: white;
}

.user-panel-inner p{
    position: 'relative';
    width: 100%;
    text-align: center;
    margin: 10px;
}
.friendship-panel{
    display:flex;
    justify-content: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.friendship-panel-inner {
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(20px, auto));
    grid-template-columns: repeat(auto-fill, minmax(auto, auto));
    grid-auto-flow: row;
    padding: 20px;
    gap: 10px;
}
.friendship-panel-inner p {
    margin-bottom: 0px !important;
}
.friendship-panel-inner b {
    white-space: nowrap;
    text-align: center;
    align-self: center;
}

.token-panel{
    position: relative;
    margin: 20px;
    padding: 5px;
    color: #000;
    width: 200px;
    border: 2px solid #000;
    background-color: white;
    outline: none;
    transition: 0.4s;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.token-panel img{
    margin-right: 5px;
    width: 30px;
    height: 30px;
}
.token-panel h4{
    color: black;
}
.token-panel p{
    margin: 5px
}
.token-panel-top{
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.token-panel-inner p{
    border: 1px solid black;
    background-color: white;
}

.sprite{
    position: relative;
    left: 9em;
    top: -1em
}

.hidden_mobile_btn{
    display: block !important;
}
