.scrollable-gallery {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
}
.scrollable-gallery-vert{
  position: relative;
  overflow: hidden;
  flex-direction: column;
  white-space: nowrap;
  height: 100%;
  width: 100%;
}

.scrollable-gallery img {
  display: inline-block;
  margin: 5px;
}

.mNFT:hover {
  opacity: 0.97;
  border: 3px black solid;
}

.mNFT > img{
  border: 3px red solid;
}

/* Default styles */
.NFT-panel {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  box-sizing: border-box;
}
/* Default styles */
.NFT-panel2 {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  box-sizing: border-box;
}

/* For very small screens (less than or equal to 480px) */
@media (max-width: 480px) {
  .NFT-panel {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* For small screens (less than or equal to 768px but greater than 480px) */
@media (max-width: 768px) and (min-width: 481px) {
  .NFT-panel {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
