#profilePage {
    margin-top: 10em;
}


h1,h2,h3,h4{
  font-family: 'Work Sans', sans-serif;
}

.profile-title-panel h1{
    color: black;
    position: relative;
    text-align: center;
    top: -0.3em;
}
.loading-panel{
  width: 40%;
  height: 50px;
  align-self: center;
  text-align: center;

}
.profilePage-container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    height: 100%;
    width: 55vw;
    align-items: stretch;
  }
 .ProfilePage-topRow{
    display: flex;
    gap: 35px;
    width: 90%;
    margin: auto;
    height: 46em;
    justify-content: space-around;
  }
  .userInfo {
    grid-column: span 1;
  }

  .nftGallery {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }


  .panel.friendship-panel {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.panel .nft-image {
    max-width: 100%;
    height: auto;
    border: 2px solid grey;
    border-radius: 10px;
    box-shadow: 1px 2px 2px black;
    aspect-ratio: 16/9;
}

.panel .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-auto-rows: 1fr;
    height: 100%;
}
.grid-item0 {
  grid-column: span 2;
}
.panel .level-info-container {
    grid-column: 1 / 3;
}

.panel .level-info-text {
    display: flex;
    font-style: italic;
    font-size: 14px;
    width: 100%;
    height: 16px;
    justify-content: space-between;
}

.panel .level-info-text.large {
    height: 26px;
}

.panel .btn-panel {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.panel .button {
    margin: 0 10px;
}

.attribute-panel {
    border-radius: 8px;
    padding: 5px;
    padding-bottom: 30px;
    margin: 0px;
    font-size: 14px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s;
    height: 100%;
  }

  .attribute-panel h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .panel-row {
    display: flex;
    justify-content: space-between;
  }

  .panel-key {
    font-weight: bold;
    color: #555;
  }

  .panel-value {
    color: #777;
  }
  .ref-link{
    display: flex;
    min-height: 110px;
    flex-direction: column;
    justify-content: start !important;
  }
  .ref-text{
    position: relative;
    top: -1.5em;
    color: darkblue;
  }
  .ref-text:hover{
    cursor:pointer;
    color: red;
  }
  .refresh{
    //height: 10px;
    width: 10px;
  }
