.alert-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom-alert {
    position: fixed;
    top: 6.4em;
    z-index: 10;
    border: 2px solid #000;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 10pt;
    opacity: 0.95;
  }

  .custom-alert .flex-shrink-0 {
    margin-right: 10px;
  }

  .custom-alert .mb-0 {
    margin-bottom: 0;
  }

  .custom-alert p {
    margin: 0;
    font-weight: bold;
  }
  .error-alert{
    background-color: #FFFF6E;
    color: #856404;
  }
  .success-alert{
    background-color: palegreen;
    color: #856404;
    border-radius: 4px;
  }
