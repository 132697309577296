
.welcome-img{
    z-index: 5;
  }
  .App-link {
    color: #61dafb;
  }
  .wallet {
    margin: 0px -10px 10px -5px;
  }

  .wallet-button {
    position: relative;
    width: 9em;
    height: 45px;
    transform: scale(0.8);
    font-size: 17px;
    font-weight: bold;
    border-radius: 25px !important;
    border: 3px white solid;
    box-shadow: none;
    background-color: rgba(200, 137, 206, 0.733);
    overflow-y: hidden;
  }
  .wallet-button:hover{
    background-color: rgba(177, 12, 192, 0.733);
    border: 3px grey solid;
  }
  .screenBlock{
    all: initial;
    position:absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
  }

  .vhead{
    color:black;
    font-size: 20pt;
    padding-bottom: 20px;
    text-align: center;
  }
  .arbi{
    color:blue;
    padding: 0px;
  }
  .pls{
    color:purple;
  }

   .hidden {
    display: none;
    pointer-events: none;
    opacity: 0;
  }

  .Form_btn {
    width: 10em;
    height: 3em;
  }
  .customInput{
    position:relative;
    top: 1em;
    width: 15em;
    height: 3rem;
    border: 3px solid black;
    border-radius: 4px;
    padding:5px;
  }
  .separator{
    height: 200px;
  }
  .demoDapp-container{
    position: relative;
    overflow: hidden;
    padding-top: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-image: url("../../assets/images/background/sommi-fan-token-logo-white-tiled-077.png");
    background-repeat: repeat repeat;
    background-size: 70%;

  }
  .about_title{
    text-align: center;
  }
  .centered-div{
    position:relative;
  }
  .customForm {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 850px; /* Adjust as needed */
    background-color: white;
    padding:50px;
    text-align: center;
    line-height: 1.5em; /* Adjust as needed */
    /*added*/
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    color: #000;
    }
    .customInput-container{
      position: relative;
      width: 70%;
      padding: 0%;
      margin: 30px;
      margin-bottom: 0px;
      align-self: center;
      top: -4em;
    }
    .customInput{
      width: 100%;
    }
    .slider{
      max-width: 50%;
    }
  .vesting{
    min-height: 900px; /* Adjust as needed */
    max-height: 1000px; /* Adjust as needed */
  }
  .whitebox {
    background-color: white;
    border: 2px solid red;
    padding: 5px;
    background: white;
  }
  .vbtn_container {
    display: flex;
    justify-content: center;
    position:relative;
    padding: 15px;
  }
