.horizontal-banner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  padding: 10px 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  margin: 10px auto;
}

.horizontal-banner.small {
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr)); /* Reduce min-width for smaller screens */
  gap: 10px; /* Smaller gap */
  padding: 5px 10px; /* Reduce padding */
  font-size: 10px; /* Smaller font */
  height: 50px;
}

.banner-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px; /* Adjust padding */
}

.banner-key {
  font-size: 12px; /* Smaller font for keys */
  line-height: 1.2; /* Reduce line height */
  margin-bottom: 5px; /* Small gap between key and value */
}

.banner-value {
  font-size: 14px;
  font-weight: bold;
}

.horizontal-banner.small .banner-key {
  font-size: 8px;
  margin-bottom: 3px;
}

.horizontal-banner.small .banner-value {
  font-size: 10px;
}
  
  /* Responsive styles for small screens */
  @media screen and (max-width: 900px) {
    .horizontal-banner {
      grid-template-columns: repeat(2, 1fr); /* Force 2-column layout for small screens */
      gap: 10px; /* Reduce gap for smaller screens */
    }
  }
  