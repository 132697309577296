.CustomNumberInput {
  width: 100%;
  margin: 10px 0px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: var(--color);
  background: white;
  border: 1px dotted black;
  box-shadow: 0px 2px 2px var(--boxShadow);
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;
}

.CustomNumberInput:hover {
  border-color: var(--hoverBorder);
}

.CustomNumberInput.focused {
  border-color: var(--focusBorder);
  box-shadow: 0 0 0 3px var(--focusShadow);
}

.CustomNumberInput .input {
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1;
  grid-column: 1/2;
  grid-row: 1/3;
  color: inherit;
  background: inherit;
  border: none;
  border-radius: inherit;
  outline: 0;
}

.CustomNumberInput .input:focus-visible {
  outline: 0;
}

.CustomNumberInput .btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 23px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: var(--background);
  border: 0;
  color: inherit;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
}

.CustomNumberInput .btn.increment {
  grid-column: 2/3;
  grid-row: 1/2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid;
  border-bottom: 0;
}

.CustomNumberInput .btn.decrement {
  grid-column: 2/3;
  grid-row: 2/3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid;
}

/* Main container */
.percentage-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #f0f4ff, #dfe9ff); /* Light gradient for depth */
  padding: 20px; /* Add padding to give space around the input and buttons */
  border-radius: 12px; /* Rounded corners for a softer look */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
  border: 1px solid #b0c4de; /* Light border for definition */
}

/* Styling the input field */
.percentage-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 6px;
  text-align: center;
  outline: none;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s ease;
}

.percentage-input:focus {
  border-color: #0056b3;
}

/* Container for buttons */
.percentage-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

/* Styling each button */
.percentage-buttons button {
  flex: 1;
  padding: 8px 0;
  font-size: 14px;
  background-color: #e3f2fd; /* Light blue background */
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.percentage-buttons button:hover {
  background-color: #007bff;
  color: #ffffff;
}
