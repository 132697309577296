.media-inner{
  padding-top: 10em;
}

.highscore-head{
  color: black;
  text-align: center;
}

#referal-table {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%; /* Ensure the table takes full width */
    margin: 0 auto; /* Center the table horizontally */
  }

  .table-container {
    width: 60%;
    padding: .1em;
    margin: auto;
    background-color: white;
    margin-top: 2em;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.19) 0px 4px 2px, rgba(0, 0, 0, 0.49) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    border:3px solid black;
    border-radius: 10px;

  }

  #referal-table td,
  #referal-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #referal-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  #referal-table tr:nth-child(odd) {
    background-color: lightgrey;
  }
  
  #referal-table tr:hover {
    background-color: #ddd;
  }
  
  #referal-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: darkkhaki;
    color: white;
  }
  .highScore_header{
    padding-top: 5px; 
    margin-top: 15px;
    width: 60%;
  }